import { EnnismoreTheme } from '@/ui/theme/ennismore-theme.interface';

const fonts = {
  headline: 'gtAmericaExtended, sans-serif',
  intro: 'tiemposFineLight, Times',
  subheader: 'gtAmerica, sans-serif',
  body: 'gtAmerica, sans-serif',
};

export const hoxtonTheme: EnnismoreTheme = {
  themeKey: 'hoxton',
  logoUrl: '/brands/hoxton/images/logo.svg',
  faviconUrl: '/brands/hoxton/favicons/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 200,
      // Original theme styles:
      // fontSize: '80px',
      // lineHeight: '88px',
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.headline,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.headline,
      fontSize: '16px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '20px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#3D3937',
    textSecondary: '#8B8887',
    background: '#FEF9F3',
    primary100: '#FEF9F3',
    primary200: '#FEF2E3',
    primary300: '#F0E4D7',
    primary400: '#FFE7D9',
    primary500: '#FF9272',
    secondary100: '#F6F6F6',
    secondary200: '#D9D9D9',
    secondary300: '#626865',
    secondary400: '#3B423F',
    error: '#B00020',
  },
  buttons: {
    primary: {
      fontFamily: 'gtAmericaExtended, sans-serif',
      backgroundColor: '#3D3937',
    },
    secondary: {
      fontFamily: 'gtAmericaExtended, sans-serif',
      backgroundColor: 'transparent',
      color: '#3D3937',
      border: '1px solid #3D3937',
    },
  },
  forms: {
    input: {},
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'gtAmericaExtended',
      fontWeight: 200,
      sources: {
        woff: '/brands/hoxton/fonts/GTAmerica/GTAmerica-ExtendedRegular.woff',
      },
    },
    {
      fontFamily: 'tiemposFine',
      fontWeight: 200,
      sources: {
        woff: '/brands/hoxton/fonts/TiemposFine/TiemposFine-Light.woff',
      },
    },
    {
      fontFamily: 'gtAmerica',
      fontWeight: 200,
      sources: {
        woff: '/brands/hoxton/fonts/GTAmerica/GTAmerica-Light.woff',
      },
    },
  ],
  keylines: {
    border: '1px solid #F0E4D7',
  },
  componentProperties: {
    search: {
      results: {
        roomSpecColumns: 2,
      },
    },
    sidebarCards: {
      secondary: {
        borderRadius: '8px',
        padding: 'xs',
        backgroundColor: 'background',
        borderColor: 'primary300',
        color: 'textPrimary',
      },
      primary: {
        borderRadius: '0px',
        padding: 'l',
        backgroundColor: 'primary200',
        color: 'textPrimary',
      },
    },
    banners: {
      backgroundColor: 'primary200',
      color: 'textPrimary',
    },
    footer: {
      backgroundColor: '#FEF9F3',
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
