import { isProductionEnvironment } from '@/env';

import { twentyOneCBrandConfig } from '../../21c/21c.brand';
import { twentyFiveHoursBrandConfig } from '../../25hours/25hours.brand';
import { estelleBrandConfig } from '../../estelle/estelle.brand';
import { gleneaglesBrandConfig } from '../../gleneagles/gleneagles.brand';
import { hoxtonBrandConfig } from '../../hoxton/hoxton.brand';
import { hydeBrandConfig } from '../../hyde/hyde.brand';
import { mamaShelterBrandConfig } from '../../mamashelter/mamashelter.brand';
import { mondrianBrandConfig } from '../../mondrian/mondrian.brand';
import { morgansOriginalsBrandConfig } from '../../morgansoriginals/morgansoriginals.brand';
import { orientExpressBrandConfig } from '../../orientexpress/orientexpress.brand';
import { townhouseBrandConfig } from '../../townhouse/townhouse.brand';

const isProduction = isProductionEnvironment();

/**
 * A map of all brand configurations.
 *
 * NB: This is going to become a scaling bottleneck - this object size is proportional to the number
 *     of brands, and it's bundled into the client!
 *
 * Ideally, we would keep this whole object from being sent to the client by injecting it at the server-level on every page.
 * Unfortunately, Next.js doesn't support `getServerSideProps` within `_app.tsx` (only `getInitialProps`, which is expected
 * to be evaluated on the client side after initial page load). We could load from an API endpoint... but that's not really
 * viable when it's being run on the server-side. Feels like we would end up overcomplicating the solution.
 *
 * Essentially, hoping that by the time it becomes an issue, Next.js' `/app` directory with server components will be stable. It'll solve this.
 */
export const AvailableBrandConfigurations = {
  gleneagles: gleneaglesBrandConfig({ isProduction }),
  hoxton: hoxtonBrandConfig({ isProduction }),
  townhouse: townhouseBrandConfig({ isProduction }),
  estelle: estelleBrandConfig({ isProduction }),
  '25hours': twentyFiveHoursBrandConfig({ isProduction }),
  '21c': twentyOneCBrandConfig({ isProduction }),
  morgansoriginals: morgansOriginalsBrandConfig({ isProduction }),
  mamashelter: mamaShelterBrandConfig({ isProduction }),
  mondrian: mondrianBrandConfig({ isProduction }),
  hyde: hydeBrandConfig({ isProduction }),
  orientexpress: orientExpressBrandConfig({ isProduction }),
} as const;
