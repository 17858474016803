import { Global, css } from '@emotion/react';
import React from 'react';
import Modal from 'react-modal';

import { useTheme } from '@/ui/theme';

import CloseButton from './CloseButton.component';

interface CopyModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  contentMaxWidth?: number;
  onRequestClose: () => void;
  padding?: number;
  backgroundColor?: string;
}

export const CopyModal = (props: CopyModalProps) => {
  const {
    componentProperties: { modals },
  } = useTheme();
  return (
    <React.Fragment>
      <Global
        styles={css`
          .CopyModal__Body--open {
            overflow-y: hidden;
          }
          .CopyModal.ReactModal__Content {
            transform: scale(0.9);
            transition: all 0.2s ease;
            opacity: 0;
          }
          .CopyModal.ReactModal__Content--after-open {
            left: 0;
            transform: scale(1);
            opacity: 1;
          }
          .CopyModal.ReactModal__Content--before-close {
            transform: scale(0.9);
            opacity: 0;
          }
          .CopyModal.ReactModal__Content {
            overflow-y: auto;

            @media all and (max-width: 720px) {
              padding: 68px 32px 32px !important;
            }
          }
          .OverlayCopyModal {
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1200;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow-y: auto;
            /* position:  */
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            span {
              ${modals?.color && `color: ${modals?.color};`}
            }
            h5 {
              span {
                ${modals?.title?.textTransform &&
                `text-transform: ${modals?.title?.textTransform};`}
                ${modals?.title?.fontFamily &&
                `font-family: ${modals?.title?.fontFamily};`}
                ${modals?.title?.fontSize &&
                `font-size: ${modals?.title?.fontSize};`}
                ${modals?.title?.lineHeight &&
                `line-height: ${modals?.title?.lineHeight};`}
                ${modals?.title?.letterSpacing &&
                `letter-spacing: ${modals?.title?.letterSpacing};`}
                @media all and (max-width: 730px) {
                  ${modals?.title?.mobile?.fontSize &&
                  `font-size: ${modals?.title?.mobile?.fontSize};`}
                  ${modals?.title?.mobile?.lineHeight &&
                  `line-height: ${modals?.title?.mobile?.lineHeight};`}
                ${modals?.title?.mobile?.letterSpacing &&
                  `letter-spacing: ${modals?.title?.mobile?.letterSpacing};`}
                }
              }
            }
          }

          .OverlayCopyModal.ReactModal__Overlay {
            transition: all 0.2s ease;
            opacity: 0;
          }
          .OverlayCopyModal.ReactModal__Overlay--after-open {
            opacity: 1;
          }
          .OverlayCopyModal.ReactModal__Overlay--before-close {
            opacity: 0;
          }
        `}
      />
      <Modal
        bodyOpenClassName="CopyModal__Body--open"
        className="CopyModal"
        overlayClassName="OverlayCopyModal"
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
            zIndex: 1200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            overflowY: 'auto',
            ...modals?.overlay,
          },
          content: {
            borderRadius: 0,
            border: 'none',
            padding: props.padding || 64,
            height: 'auto',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: 'auto',
            marginTop: '5vh',
            maxWidth: props.contentMaxWidth || 'inherit',
            marginBottom: '5vh',
            marginLeft: '24px',
            marginRight: '24px',
            backgroundColor:
              modals?.backgroundColor ?? props.backgroundColor ?? 'white',
            outline: 'none',
          },
        }}
      >
        <div
          css={css`
            position: absolute;
            right: 32px;
            top: 32px;
          `}
        >
          <CloseButton onClick={props.onRequestClose} />
        </div>
        {props.children}
      </Modal>
    </React.Fragment>
  );
};

export default CopyModal;
