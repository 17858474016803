import { twentyOneCTheme } from '@/brand/21c/21c.theme';
import { twentyFiveHoursTheme } from '@/brand/25hours/25hours.theme';
import { estelleTheme } from '@/brand/estelle/estelle.theme';
import { gleneaglesTheme } from '@/brand/gleneagles/gleneagles.theme';
import { hoxtonTheme } from '@/brand/hoxton/hoxton.theme';
import { hydeTheme } from '@/brand/hyde/hyde.theme';
import { mamaShelterTheme } from '@/brand/mamashelter/mamashelter.theme';
import { mondrianTheme } from '@/brand/mondrian/mondrian.theme';
import { morgansOriginalsTheme } from '@/brand/morgansoriginals/morgansoriginals.theme';
import { orientExpressTheme } from '@/brand/orientexpress/orientexpress.theme';
import { townhouseTheme } from '@/brand/townhouse/townhouse.theme';

export const AvailableThemes = {
  hoxton: hoxtonTheme,
  gleneagles: gleneaglesTheme,
  townhouse: townhouseTheme,
  estelle: estelleTheme,
  '25hours': twentyFiveHoursTheme,
  '21c': twentyOneCTheme,
  morgansoriginals: morgansOriginalsTheme,
  mamashelter: mamaShelterTheme,
  hyde: hydeTheme,
  mondrian: mondrianTheme,
  orientexpress: orientExpressTheme,
} as const;
