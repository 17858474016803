import React, { useEffect, useState } from 'react';

import { getEnvironmentKey } from '@/env';

import {
  type CookieConsentStatus,
  CookieConsentStatusContext,
  cookieConsentStatusInitialState,
} from '../consent-status';

/**
 * Includes the OneTrust script to be placed within the <Head />.
 * This should be rendered at the _app.tsx level as it requires access to the brand config,
 * which is then used to supply this component with the OneTrust ID.
 */
export const OneTrustScript = ({
  id: oneTrustID,
}: {
  id: string | undefined;
}) => {
  if (!oneTrustID) return null;

  const environment = getEnvironmentKey();

  const testEnvironment = environment !== 'live';

  return (
    <>
      {/* Asynchronously loaded OneTrust Cookies Consent Notice */}
      <script
        async
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script={`${oneTrustID}${testEnvironment ? '-test' : ''}`}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: `function OptanonWrapper(){}` }}
      />
    </>
  );
};

export const OneTrustCookieSettingsButton = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <a
    href="#cookie-banner"
    onClick={() => {
      // TODO: extend window to include Optaonon
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: Optanon actually gets defined
      window.Optanon?.ToggleInfoDisplay();
    }}
  >
    {children}
  </a>
);

export function determineStatus() {
  if (
    // This eslint rule is misleading - an optional chain functions differently than this code.
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    typeof window === 'undefined' ||
    typeof window.OnetrustActiveGroups === 'undefined'
  ) {
    return cookieConsentStatusInitialState;
  }

  const activeGroups = window.OnetrustActiveGroups;

  // These codes have been verified as consistent across all domain configurations at the time of writing.
  // They may change in the future.
  // More details: https://ennismore.atlassian.net/wiki/spaces/BP/pages/3820257284/Cookie+Consent+Status
  return {
    necessary: activeGroups.includes('C0001'),
    performance: activeGroups.includes('C0002'),
    functional: activeGroups.includes('C0003'),
    targeting: activeGroups.includes('C0004'),
  };
}

export function OneTrustConsentStatusProvider(props: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<CookieConsentStatus>(() =>
    determineStatus()
  );

  useEffect(() => {
    if (typeof window.OnetrustActiveGroups === 'undefined') {
      return;
    }

    // No cleanup needed as this will be overwritten on each render
    window.OptanonWrapper = () => {
      setState(determineStatus());
    };
  }, []);

  return (
    <CookieConsentStatusContext.Provider value={state}>
      {props.children}
    </CookieConsentStatusContext.Provider>
  );
}
