import { css } from '@emotion/react';
import * as Popover from '@radix-ui/react-popover';
import type { Locale } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import type { DayPickerDefaultProps } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { Box, Flex } from '@/box';
import { useTranslation } from '@/i18n';
import { Label } from '@/ui/form';
import { useTheme } from '@/ui/theme';

import { EndDateField, StartDateField } from '.';

const isBeforeToday = (date: Date) => {
  const today = new Date();

  today.setHours(0, 0, 0, 0);

  return date < today;
};

const isBeforeDate = (secondDate: Date) => (firstDate: Date) => {
  secondDate.setHours(0, 0, 0, 0);

  return firstDate < secondDate;
};

const DateFieldLabels = ({
  startDateId,
  endDateId,
}: {
  startDateId: string;
  endDateId?: string;
}) => {
  const { t } = useTranslation();
  const { forms } = useTheme();
  return (
    <Flex>
      <Box
        width={[endDateId ? 'calc(50% - 7.5px)' : 1, 'calc(50% - 7.5px)']}
        marginRight={endDateId ? 15 : 0}
        {...forms?.searchForm?.startDateFieldContainer}
      >
        <Label htmlFor={`field-${startDateId}`} isRequired>
          {t('search:form.checkInLabel')}
        </Label>
      </Box>
      {endDateId ? (
        <Box
          width={'calc(50% - 7.5px)'}
          {...forms?.searchForm?.endDateFieldContainer}
        >
          <Label htmlFor={`field-${endDateId}`} isRequired>
            {t('search:form.checkOutLabel')}
          </Label>
        </Box>
      ) : null}
    </Flex>
  );
};

export interface DatePickerProps {
  startDate: string | undefined;
  onStartDateChange: (day?: Date) => void;
  endDate?: string;
  onEndDateChange?: (day?: Date) => void;
  singleDate?: boolean;
  allowSelectionFromDate?: string;
  locale: Intl.Locale;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>();
  const [dateChangeMode, setDateChangeMode] = useState<'startDate' | 'endDate'>(
    'startDate'
  );
  /**
   * Unfortunately React Date Picker is relying on an external date library (date-fns) to localise the calendar.
   */
  const [dateFnsLocale, setDateFnsLocale] = useState(enGB);

  const selectedRange = useMemo(
    () => ({
      from: props.startDate ? dayjs(props.startDate).toDate() : undefined,
      to: props.endDate ? dayjs(props.endDate).toDate() : undefined,
    }),
    [props.startDate, props.endDate]
  );

  const {
    fonts,
    componentProperties: { calendar },
  } = useTheme();

  const triggerRef = useRef<HTMLButtonElement>();
  const startDateTriggerRef = useRef<HTMLButtonElement>();
  const endDateTriggerRef = useRef<HTMLButtonElement>();

  const startDateId = 'checkInDate';
  const endDateId = 'checkOutDate';

  const handleRangeChange = (day: Date) => {
    switch (dateChangeMode) {
      case 'startDate':
        // if new start date is before the current end date
        if (dayjs(day).isBefore(dayjs(props.endDate).toDate(), 'day')) {
          props.onStartDateChange(day);
          // if new start date is after the current end date
        } else {
          props.onStartDateChange(day);
          props.onEndDateChange?.(undefined);
        }
        setDateChangeMode('endDate');
        break;
      case 'endDate':
        // if new end date is before the current start date
        if (dayjs(day).isBefore(dayjs(props.startDate).toDate(), 'day')) {
          props.onStartDateChange(day);
          props.onEndDateChange?.(undefined);
          // if new end date is the same as the current from date
        } else if (dayjs(day).isSame(dayjs(props.startDate).toDate(), 'day')) {
          return;
          // if new end date is after the current start date
        } else {
          props.onEndDateChange?.(day);
          setDateChangeMode('startDate');
          // close the calendar on confirmed date range
          triggerRef.current?.click();
          // and move focus to end date input
          endDateTriggerRef.current?.focus();
        }
        break;
    }
  };

  const handleDateChange = (day: Date) => {
    props.onStartDateChange(day);
    // close the calendar when the new date is picked
    triggerRef.current?.click();
    // and move focus back to the start date input
    startDateTriggerRef.current?.focus();
  };

  const toggleCalendar = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerRef.current?.click();
  };

  const focusOnStartDateField = () => {
    setFocusedInput('startDate');
    setDateChangeMode('startDate');
  };

  const focusOnEndDateField = () => {
    setFocusedInput('endDate');
    setDateChangeMode('endDate');
  };

  const commonDayPickerProps: DayPickerDefaultProps = {
    disabled: props.allowSelectionFromDate
      ? // Covers cases where the opening date is in the past and when the current date is before the opening date
        isBeforeDate(dayjs(props.allowSelectionFromDate).toDate())(
          dayjs().toDate()
        )
        ? isBeforeDate(dayjs(props.allowSelectionFromDate).toDate())
        : isBeforeToday
      : isBeforeToday,
    weekStartsOn: 1,
    fromMonth:
      props.allowSelectionFromDate &&
      dayjs(props.allowSelectionFromDate).isAfter(dayjs())
        ? dayjs(props.allowSelectionFromDate).toDate()
        : dayjs().toDate(),
  };

  /**
   * Dynamically load in a locale file to localise the calendar.
   */
  useEffect(() => {
    import(`date-fns/locale/${props.locale.baseName}/index.js`).then(
      (localeFile) => setDateFnsLocale(localeFile.default as Locale)
    );
  }, [props.locale.baseName]);

  return (
    <>
      <DateFieldLabels
        startDateId={startDateId}
        endDateId={props.singleDate ? undefined : endDateId}
      />
      <Popover.Root>
        <Flex width="full" position="relative">
          <Popover.Trigger
            ref={triggerRef as any}
            css={css`
              position: absolute;
              height: 100%;
              width: 100%;
              pointer-events: none;
            `}
            tabIndex={-1}
            aria-hidden
          />
          <StartDateField
            startDateId={startDateId}
            value={props.startDate}
            onFocusHandler={focusOnStartDateField}
            onClickHandler={(e) => {
              focusOnStartDateField();
              toggleCalendar?.(e);
            }}
            triggerRef={startDateTriggerRef as any}
            singleDate={props.singleDate}
          />
          {props.singleDate ? null : (
            <EndDateField
              endDateId={endDateId}
              value={props.endDate}
              onFocusHandler={focusOnEndDateField}
              onClickHandler={(e) => {
                focusOnEndDateField();
                toggleCalendar?.(e);
              }}
              triggerRef={endDateTriggerRef as any}
            />
          )}
        </Flex>
        <Popover.Content
          avoidCollisions={false}
          align={focusedInput === 'startDate' ? 'start' : 'end'}
          onEscapeKeyDown={() =>
            focusedInput === 'startDate'
              ? startDateTriggerRef.current?.focus()
              : endDateTriggerRef.current?.focus()
          }
          sideOffset={16}
          style={{
            boxShadow:
              calendar?.boxShadow ??
              '0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%)',
            padding: '5px',
            backgroundColor: 'white',
            borderRadius: calendar?.borderRadius ?? '3px',
            zIndex: 1000,
            border: calendar?.border,
          }}
        >
          <div
            css={css`
              .rdp {
                margin: 10px;
                font-size: 0.875rem;
                font-family: ${fonts.body};
                --rdp-accent-color: hsl(20deg 5% 23%);
                color: hsl(0deg 0% 28%);
                @media (max-width: 320px) {
                  margin: 10px 0;
                }
              }
              .rdp-cell {
                position: relative;
              }
              .rdp-caption {
                font-family: ${calendar?.header?.caption?.fontFamily ??
                fonts.subheader};
                justify-content: center;
                position: relative;
                text-align: center;
                background-color: ${calendar?.header?.backgroundColor ??
                'transparent'};
                ${calendar?.header?.color
                  ? `color: ${calendar?.header.color};`
                  : ''}
                ${calendar?.header?.caption?.textTransform
                  ? `text-transform: ${calendar?.header.caption?.textTransform};`
                  : ''}
                height: 45px;
                margin: -15px -15px 10px;
                padding: 15px 0;
                border-radius: ${calendar?.header?.borderRadius
                  ? calendar?.header.borderRadius
                  : '3px 3px 0 0'};
              }
              .rdp-nav {
                position: absolute;
                width: 100%;
                display: flex;
                justify-content: space-between;
                .rdp-button:not(:disabled):hover {
                  ${calendar?.header?.nav?.hover?.backgroundColor &&
                  `background-color: ${calendar?.header?.nav?.hover?.backgroundColor};`}
                }
              }
              .rdp-nav .rdp-nav_button_previous {
                margin-left: 15px;
              }
              .rdp-nav .rdp-nav_button_next {
                margin-right: 15px;
              }
              .rdp-button:not(:disabled):not(.rdp-day_selected):hover {
                ${calendar?.body?.date?.hover?.backgroundColor &&
                `background-color: ${calendar?.body?.date?.hover?.backgroundColor};`}
              }
              .rdp-caption_label {
                font-size: 0.875rem;
                font-weight: ${calendar?.header?.caption?.fontWeight ?? '300'};
              }
              .rdp-button[disabled]:not(.rdp-day_selected) {
                opacity: 1;
                color: rgba(61, 57, 55, 0.32);
              }
              .rdp-day_selected {
                background-color: ${calendar?.body?.date?.selected
                  ?.backgroundColor ?? 'hsl(20deg 5% 23%)'};
              }
              .rdp-day_today:not(.rdp-day_outside) {
                font-weight: normal;
              }
              .rdp-head_cell {
                color: hsla(20, 5%, 23%, 0.72);
              }
              .rdp:not([dir='rtl'])
                .rdp-day_range_start:not(.rdp-day_range_end),
              .rdp:not([dir='rtl'])
                .rdp-day_range_end:not(.rdp-day_range_start) {
                border-radius: 100%;
              }
              .rdp-cell:has(> .rdp-day_range_start):not(
                  :has(> .rdp-day_range_end)
                )::before {
                content: '';
                background-color: ${calendar?.body?.date?.selectedMiddle
                  ?.backgroundColor ?? 'hsla(20, 5%, 23%, 0.12)'};
                display: inline-block;
                position: absolute;
                width: var(--rdp-cell-size);
                height: var(--rdp-cell-size);
                left: 0;
                border-radius: 100% 0 0 100%;
              }
              .rdp-cell:has(> .rdp-day_range_end):not(
                  :has(> .rdp-day_range_start)
                )::before {
                content: '';
                background-color: ${calendar?.body?.date?.selectedMiddle
                  ?.backgroundColor ?? 'hsla(20, 5%, 23%, 0.12)'};
                display: inline-block;
                position: absolute;
                width: var(--rdp-cell-size);
                height: var(--rdp-cell-size);
                left: 0;
                border-radius: 0 100% 100% 0;
              }
              .rdp-day_range_middle {
                background-color: ${calendar?.body?.date?.selectedMiddle
                  ?.backgroundColor ?? 'hsla(20, 5%, 23%, 0.12)'};
                color: hsl(0deg 0% 28%);
              }
            `}
          >
            {props.singleDate ? (
              <DayPicker
                {...commonDayPickerProps}
                mode="single"
                locale={dateFnsLocale}
                selected={dayjs(props.startDate).toDate()}
                onDayClick={handleDateChange}
                defaultMonth={dayjs(props.startDate).toDate()}
              />
            ) : (
              <DayPicker
                {...commonDayPickerProps}
                mode="range"
                locale={dateFnsLocale}
                selected={selectedRange}
                onDayClick={handleRangeChange}
                defaultMonth={
                  focusedInput === 'startDate'
                    ? dayjs(props.startDate).toDate()
                    : props.endDate
                    ? dayjs(props.endDate).toDate()
                    : dayjs(props.startDate).toDate()
                }
              />
            )}
          </div>
          <Popover.Arrow width={20} height={10} asChild>
            <svg
              role="presentation"
              focusable="false"
              viewBox="0 0 20 10"
              css={css`
                position: absolute;
                left: ${focusedInput === 'startDate' ? '50' : '-50'}px;
                ${calendar?.header?.arrow?.top &&
                `top: ${calendar?.header?.arrow?.top};`}
                transform: rotateX(180deg);
              `}
            >
              <path
                fill={calendar?.header?.backgroundColor ?? 'white'}
                d="M0,10 20,10 10,0z"
              />
              <path
                stroke={calendar?.header?.arrow?.stroke ?? '#dbdbdb'}
                fill="transparent"
                d="M0,10 10,0 20,10"
              />
            </svg>
          </Popover.Arrow>
        </Popover.Content>
      </Popover.Root>
    </>
  );
};
