import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';

export const mondrianBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: 'mondrian',
  themeKey: 'mondrian',
  name: 'Mondrian',
  defaultHotelCode: 'mondrian.cannes-cannes',
  hotels: [
    {
      referenceId: 'mondrian.cannes-cannes',
      code: 'mondrian.cannes-cannes',
      name: 'Mondrian Cannes',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.lescarmes-bordeaux',
      code: 'mondrian.lescarmes-bordeaux',
      name: 'Mondrian Bordeaux Les Carmes',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.ibiza-ibiza',
      code: 'mondrian.ibiza-ibiza',
      name: 'Mondrian Ibiza',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.shoreditch-london',
      code: 'mondrian.shoreditch-london',
      name: 'Mondrian London Shoreditch',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.losangeles-losangeles',
      code: 'mondrian.losangeles-losangeles',
      name: 'Mondrian Los Angeles',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.duxton-singapore',
      code: 'mondrian.duxton-singapore',
      name: 'Mondrian Singapore Duxton',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.hongkong-hongkong',
      code: 'mondrian.hongkong-hongkong',
      name: 'Mondrian Hong Kong',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.itaewon-seoul',
      code: 'mondrian.itaewon-seoul',
      name: 'Mondrian Seoul Itaewon',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.doha-doha',
      code: 'mondrian.doha-doha',
      name: 'Mondrian Doha',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.parkavenue-newyork',
      code: 'mondrian.parkavenue-newyork',
      name: 'Mondrian Park Avenue',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.condesa-mexicocity',
      code: 'mondrian.condesa-mexicocity',
      name: 'Mondrian Mexico City Condesa',
      dialect: 'ohip',
    },
    {
      referenceId: 'mondrian.southbeach-miami',
      code: 'mondrian.southbeach-miami',
      name: 'Mondrian South Beach',
      dialect: 'ohip',
    },
  ],
  homeUrl: 'https://book.ennismore.com/hotels/mondrian',
  domains: [
    'booking.mondrian.local',
    'mondrian.dev.env.ennismore.com',
    'mondrian.staging.env.ennismore.com',
    'booking.mondrianhotels.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US'],
  },

  maxRoomCountPerBooking: 10,

  search: {
    errorResolutionType: 'contact-button',
  },

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 2,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 3,
    },
    {
      adults: 3,
      children: 1,
    },
    {
      adults: 4,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy: 'https://book.ennismore.com/privacy-policy',
        cookiePolicy: 'https://book.ennismore.com/policy/cookie-notice',
        termsOfUse: 'https://book.ennismore.com/legal-notice',
        contact: 'https://ennismore.com/contact/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  showAveragePricePerNight: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],

  features: {
    bookingManagement: true,
  },

  marketingEmailSignUpAtCheckout: false,
  collectPrivacyPolicyConsentAtCheckout: true,
  ohipDiscountCodeType: 'CORPORATE',

  translationOverrides: {
    'en-GB': enGB,
  },

  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-NCLQXK7K' : 'GTM-NGX39SHP',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: 'd2973099-efa9-470c-b797-df92c072a677',
    },
  },
});
