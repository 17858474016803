import { EnnismoreTheme } from '@/ui/theme/ennismore-theme.interface';

const fonts = {
  headline: 'Oriented-South, sans-serif',
  intro: 'PostGrotesk-Medium, sans-serif',
  subheader: 'Oriented-West, sans-serif',
  body: 'PostGrotesk-Regular, sans-serif',
};

export const orientExpressTheme: EnnismoreTheme = {
  themeKey: 'orientexpress',
  logoUrl: '/brands/orientexpress/images/logo.svg',
  faviconUrl: '/brands/orientexpress/favicon.ico',
  heroImage: {
    src: '/brands/orientexpress/images/oe-hero.jpg',
    alt: 'Image of a bar at La Minerva Hotel in Rome, showing a backlit shelf with bottles and decorative objects. Hanging light fixtures and blue hydrangeas are placed on the counter, with a painting of a figure with wings on the wall.',
    width: 1440,
    height: 400,
  },
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontSize: '36px',
      lineHeight: '36px',
      textTransform: 'uppercase',
      letterSpacing: '0.72px',
    },
    heading2: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '64px',
      fontWeight: 400,
    },
    heading3: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.48px',
    },
    heading6: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.48px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.48px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.32px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 300,
      letterSpacing: '0.28px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: 'white',
    textPrimary: '#041C2C',
    textSecondary: '#999999',
    background: '#F5F5F5',
    primary100: '#f7f7e1',
    primary200: 'white',
    primary300: '#818D95',
    primary400: '#e8e8e8',
    primary500: '#818D95',
    secondary100: '#f6f6f6',
    secondary200: '#d9d9d9',
    secondary300: '#818D95',
    secondary400: '#818D95',
    error: '#B00020',
  },
  buttons: {
    primary: {
      color: 'white',
      backgroundColor: '#041C2C',
      fontFamily: fonts.intro,
      fontSize: '14px',
      borderRadius: 8,
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '1.68px',
      hoverOpacity: 0.7,
      transition: 'opacity .2s ease-in-out',
    },
    secondary: {
      color: '#041C2C',
      border: '1px solid #041C2C',
      backgroundColor: 'transparent',
      fontFamily: fonts.intro,
      fontSize: '14px',
      borderRadius: 8,
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '1.68px',
      hoverOpacity: 0.7,
    },
    destructive: {
      color: '#041C2C',
      backgroundColor: 'white',
      border: '1px solid #041C2C',
      fontFamily: fonts.intro,
      fontSize: '14px',
      borderRadius: 8,
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '1.68px',
      hoverOpacity: 0.7,
    },
  },
  keylines: {
    border: '1px solid #E8E4D6',
  },
  forms: {
    heading: {
      color: '#17234E',
      fontSize: '28px',
      lineHeight: '32px',
      letterSpacing: '0.84px',
      mobile: {
        fontSize: '18px',
      },
    },
    input: { fontSize: '16px', backgroundColor: 'white' },
    label: {
      fontSize: '16px',
    },
    radioButton: {
      description: {
        color: '#041C2C',
        letterSpacing: '0.28px',
        lineHeight: '20px',
        padding: '8px 0 0 0',
      },
    },
    radioGroup: {
      divider: {
        border: '1px solid #E8E4D6',
      },
    },
    searchForm: {
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      divider: {
        display: 'block',
        paddingBottom: '44px',
        marginTop: '44px',
      },
      roomCountSelectorContainer: {
        paddingRight: '0px',
        marginRight: ['0', '45px'],
        width: [1, '120px'],
      },
      childAgeSelectorContainer: {
        paddingLeft: '0px',
        width: [1, '130px'],
        display: ['grid', 'block'],
        gap: ['20px', '0'],
        gridTemplateColumns: [
          'repeat(auto-fit, minmax(calc(50% - 10px), 1fr))',
          'none',
        ],
      },
      roomOccupancySelectorContainer: {
        paddingRight: '0px',
        marginRight: ['0', '45px'],
        width: [1, '300px'],
      },
      roomOccupancySelectorListContainer: {
        width: [1, '475px'],
      },
      datePickerContainer: {
        width: [1, '392px'],
      },
      startDateFieldContainer: {
        width: [1, '186px'],
        marginRight: '20px',
      },
      endDateFieldContainer: {
        width: [1, '186px'],
      },
    },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'Oriented-South',
      fontWeight: 400,
      sources: {
        woff2: '/brands/orientexpress/fonts/Oriented-South.woff2',
      },
    },
    {
      fontFamily: 'Oriented-West',
      fontWeight: 400,
      sources: {
        woff2: '/brands/orientexpress/fonts/Oriented-West.woff2',
      },
    },
    {
      fontFamily: 'PostGrotesk-Regular',
      fontWeight: 400,
      sources: {
        woff2: '/brands/orientexpress/fonts/PostGrotesk-Regular.woff2',
      },
    },
    {
      fontFamily: 'PostGrotesk-Medium',
      fontWeight: 400,
      sources: {
        woff2: '/brands/orientexpress/fonts/PostGrotesk-Medium.woff2',
      },
    },
  ],
  controls: {
    input: {
      borderRadius: '4px',
      border: '1px solid #818D95',
    },
  },
  componentProperties: {
    confirmation: {
      title: {
        fontSize: '36px',
        textTransform: 'uppercase',
        lineHeight: '42px',
        letterSpacing: '0.72px',
        fontFamily: fonts.headline,
        color: 'white',
        mobile: {
          fontSize: '26px',
        },
      },
      confirmationMessage: {
        color: 'white',
        fontSize: '18px',
      },
      header: {
        backgroundColor: '#713C35',
      },
      manageMyBookingLink: {
        fontSize: '12px',
        textTransform: 'uppercase',
        textDecoration: 'underline',
        letterSpacing: '1.44px',
        fontFamily: fonts.intro,
      },
      bookingConfirmationContainer: {
        divider: {
          borderColor: '#E8E4D6',
        },
      },
      headerImage: {
        margin: '0',
        width: '100%',
        height: '480px',
        paddingBottom: '48px',
        objectFit: 'cover',
      },
      anyQuestionsCard: {
        title: {
          mobile: {
            fontSize: '18px',
          },
        },
      },
      restaurantsCard: {
        title: {
          mobile: {
            fontSize: '18px',
          },
        },
      },
      whereToFindUsCard: {
        title: {
          mobile: {
            fontSize: '18px',
          },
        },
        planYourJourneyLink: {
          color: '#1A1A1A',
        },
      },
    },
    consentInputs: {
      title: {
        display: 'none',
      },
    },
    bookingManagement: {
      title: {
        textTransform: 'uppercase',
        fontSize: '36px',
        lineHeight: '42px',
        letterSpacing: '0.72px',
        fontFamily: fonts.headline,
        mobile: {
          fontSize: '28px',
          lineHeight: '34px',
          letterSpacing: '0.56px',
        },
      },
      bookingCard: {
        header: {
          backgroundColor: '#E8E4D6',
          borderBottom: '1px solid #E8E4D6',
          fontFamily: fonts.subheader,
          textTransform: 'none',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.28px',
        },
        cancellationModal: {
          confirmButton: {
            variant: 'primary',
          },
        },
      },
    },
    header: {
      height: 90,
      logo: {
        borderRight: 'none',
      },
      wizard: {
        step: {
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: 400,
          opacity: 0.5,
          fontFamily: fonts.body,
          letterSpacing: '1.44px',
          active: {
            opacity: 1,
          },
        },
      },
    },
    footer: {
      backgroundColor: '#E8E4D6',
      color: '#041C2C',
      fontFamily: fonts.body,
      letterSpacing: '0.32px',
      textDecoration: 'none',
      fontSize: '16px',
      copyright: {
        display: 'none',
      },
      mobile: {
        link: {
          marginBottom: '28px',
        },
      },
    },
    searchPage: {
      header: {
        backgroundColor: 'white',
      },
    },
    search: {
      results: {
        bedroomAvailabilityList: {
          rowGap: 'xl',
        },
        roomSpecColumns: 2,
        sidebarContainer: {
          cancellationPolicy: {
            backgroundColor: 'transparent',
            border: '1px solid #E8E4D6',
            borderRadius: '6px',
            color: '#041C2C',
            lineHeight: '20px',
            title: {
              lineHeight: '30px',
            },
            mobile: {
              textTransform: 'uppercase',
              color: '#041C2C',
              fontFamily: fonts.intro,
              letterSpacing: '1.44px',
              fontSize: '12px',
              lineHeight: '12px',
            },
          },
          hotelPerks: {
            backgroundColor: 'hsl(46.67deg 28.12% 87.45% / 40%)',
            border: 'none',
            mobile: {
              backgroundColor: '#E8E4D6',
              title: {
                fontSize: '20px',
              },
              button: {
                textTransform: 'uppercase',
                fontSize: '14px',
                letterSpacing: '1.68px',
                lineHeight: '14px',
              },
            },
          },
        },
      },
      collapsibleForm: {
        searchSummary: {
          border: 'none',
          lineHeight: '38px',
          mobile: {
            padding: '16px',
            title: {
              fontSize: '16px',
            },
          },
          modifySearchButton: {
            textTransform: 'uppercase',
            fontSize: '14px',
            letterSpacing: '1.68px',
            lineHeight: '14px',
            fontFamily: fonts.intro,
            mobile: {
              fontSize: '12px',
              lineHeight: '12px',
              letterSpacing: '1.44px',
            },
          },
        },
      },
    },
    modals: {
      title: {
        textTransform: 'uppercase',
        fontFamily: fonts.headline,
        fontSize: '36px',
        letterSpacing: '0.72px',
        lineHeight: '42px',
        mobile: {
          fontSize: '28px',
          lineHeight: '34px',
          letterSpacing: '0.56px',
        },
      },
      overlay: {
        backdropFilter: 'blur(1px)',
      },
    },
    calendar: {
      border: '1px solid #818D95',
      borderRadius: '4px',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
      header: {
        color: '#041C2C',
        backgroundColor: '#E8E4D6',
        borderRadius: '4px 4px 0 0',
        arrow: {
          stroke: '#818D95',
          top: '-1px',
        },
        caption: {
          fontWeight: 500,
        },
        nav: {
          hover: {
            backgroundColor: 'hsl(204deg 83% 9% / 10%)',
          },
        },
      },
      body: {
        date: {
          hover: {
            backgroundColor: 'hsl(204deg 83% 9% / 10%)',
          },
          selected: { backgroundColor: '#041C2C' },
          selectedMiddle: { backgroundColor: '#E8E4D6' },
        },
      },
    },
    bedroomCard: {
      roomTitle: {
        fontSize: '28px',
        letterSpacing: '0.56px',
      },
      roomRateName: {
        fontSize: '20px',
        letterSpacing: '0.4px',
        paddingBottom: '16px',
      },
      description: {
        letterSpacing: '0.28px',
        lineHeight: '20px',
      },
      totalPrice: {
        fontFamily: fonts.subheader,
      },
      roomSpecItem: {
        letterSpacing: '0.28px',
        color: '#818D95',
        value: {
          color: '#041C2C',
        },
      },
    },
    sidebarCards: {
      secondary: {
        borderRadius: '0px',
        padding: 'm',
        backgroundColor: 'background',
        borderColor: 'primary300',
        color: 'textPrimary',
      },
      primary: {
        borderRadius: '0px',
        padding: 'l',
        backgroundColor: 'primary200',
        borderColor: 'white',
        color: 'textPrimary',
      },
    },
    checkoutSidebar: {
      cancellationPolicy: {
        title: {
          lineHeight: '30px',
        },
        description: {
          color: '#041C2C',
        },
        borderRadius: '6px',
      },
    },
    staySummary: {
      border: 'none',
      title: {
        fontSize: '26px',
        lineHeight: '32px',
        letterSpacing: '0.78px',
        mobile: {
          fontSize: '22px',
        },
      },
      summarySection: {
        padding: '28px 24px',
        mobile: {
          padding: '8px 16px 16px',
        },
      },
      dataRow: {
        label: {
          opacity: 1,
        },
        value: {
          fontFamily: fonts.intro,
        },
      },
      dropdown: {
        textTransform: 'uppercase',
        opacity: 1,
        fontSize: '12px',
        fontFamily: fonts.intro,
        letterSpacing: '1.44px',
      },
      divider: {
        borderColor: '#E8E4D6',
      },
    },
    imageSlider: {
      paginator: {
        color: '#041C2C',
      },
    },
    cards: {
      primary: {
        border: 'none',
        divider: {
          borderColor: '#E8E4D6',
        },
      },
    },
    banners: {
      backgroundColor: 'primary200',
      color: 'textPrimary',
    },
    page: {
      background: '#F5F5F5',
      title: {
        mobile: {
          fontSize: '28px',
          lineHeight: '34px',
          letterSpacing: '0.56px',
          textTransform: 'uppercase',
          fontFamily: fonts.headline,
        },
      },
    },
    hotelPerk: {
      title: {
        fontFamily: fonts.intro,
      },
      description: {
        opacity: 1,
      },
    },
    halfScreenLayout: {
      borderRight: 'none',
    },
    paymentMethods: {
      googlePay: {
        borderRadius: '8px',
      },
      applePay: {
        borderRadius: '8px',
      },
    },
  },

  icons: {
    ADA: '/brands/orientexpress/icons/accessible_rooms.svg',
    BED_SIZE: '/brands/orientexpress/icons/bed_size.svg',
    BREAKFAST: '/brands/orientexpress/icons/breakfast.svg',
    BAR: '/brands/orientexpress/icons/mini_bar.svg',
    DOG: '/brands/orientexpress/icons/pet_friendly.svg',
    CAPACITY: '/brands/orientexpress/icons/room_capacity.svg',
    SIZE: '/brands/orientexpress/icons/room_size.svg',
    ROOM_WITH_VIEW: '/brands/orientexpress/icons/room_view.svg',
    WIFI: '/brands/orientexpress/icons/wifi.svg',
  },
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
