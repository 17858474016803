import { css } from '@emotion/react';
import React from 'react';

import CancellationPolicy from '@/booking/components/CancellationPolicy.component';
import CancellationPolicyPrePurchaseBody from '@/booking/components/CancellationPolicyPrePurchaseBody.component';
import SidebarSection from '@/core/components/layout/SidebarSection.component';
import { useTheme } from '@/ui/theme';

export const SearchResultsCancellationPolicySidebarSectionContainer: React.FC<{
  hotelReferenceId: string;
}> = ({ hotelReferenceId }) => {
  const {
    componentProperties: { search },
  } = useTheme();
  return (
    <SidebarSection
      css={css({
        ...search.results.sidebarContainer?.cancellationPolicy,
        span: {
          color: search.results.sidebarContainer?.cancellationPolicy?.color,
          lineHeight:
            search.results.sidebarContainer?.cancellationPolicy?.lineHeight,
        },
        h3: {
          span: search.results.sidebarContainer?.cancellationPolicy?.title,
        },
      })}
      testId="search-results-cancellation-policy-sidebar-section"
    >
      <CancellationPolicy hotelReferenceId={hotelReferenceId}>
        <CancellationPolicyPrePurchaseBody
          hotelReferenceId={hotelReferenceId}
        />
      </CancellationPolicy>
    </SidebarSection>
  );
};
