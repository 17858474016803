import { css } from '@emotion/react';
import React, { useState } from 'react';

import CopyModal from '@/core/components/controls/CopyModal.component';
import { useTranslation } from '@/i18n';
import { AnchorButton } from '@/ui/controls';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { Heading } from '@/ui/typography';

import HotelPerks, { HotelPerksProps } from './HotelPerks.component';

export const MobileHotelPerks = (props: HotelPerksProps) => {
  const { colors, componentProperties } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('search');

  const title = t('perksSidebar.mobileTitle');
  const ctaLabel = t('perksSidebar.mobileCtaLabel');

  const { perks } = props;

  if (!perks || perks.length <= 0) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        css={css`
          background: ${componentProperties.search.results.sidebarContainer
            ?.hotelPerks?.mobile?.backgroundColor ?? colors.primary200};
          border-left: none;
          border-right: none;
          padding: 24px 19px;
          color: ${colors.textPrimary};
          text-align: center;
        `}
      >
        {title.length > 0 && (
          <Heading
            style="heading5"
            as="h5"
            css={css(
              componentProperties.search.results.sidebarContainer?.hotelPerks
                ?.mobile?.title
            )}
          >
            {title}
          </Heading>
        )}
        <Spacer s="xxs" />
        <AnchorButton
          onClick={() => setIsOpen(true)}
          css={
            componentProperties.search.results.sidebarContainer?.hotelPerks
              ?.mobile?.button
          }
        >
          {ctaLabel}
        </AnchorButton>
      </div>
      <CopyModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        padding={40}
        backgroundColor={
          colors[componentProperties.sidebarCards.primary.backgroundColor]
        }
      >
        <HotelPerks {...props} />
      </CopyModal>
    </React.Fragment>
  );
};

export default MobileHotelPerks;
