import React from 'react';

import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { Heading } from '@/ui/typography';

interface BookingWizardStepTitleProps {
  children: string;
}

export const BookingWizardStepTitle = (props: BookingWizardStepTitleProps) => {
  const {
    componentProperties: { page },
  } = useTheme();
  return (
    <>
      <Spacer s="xl" />
      <Heading
        as="h1"
        style="heading1"
        css={{
          ['@media all and (max-width: 730px)']: page?.title?.mobile,
        }}
      >
        {props.children}
      </Heading>
      <Spacer s="xl" />
    </>
  );
};

export default BookingWizardStepTitle;
