import { css } from '@emotion/react';
import Image from 'next/image';
import React from 'react';

import { Flex } from '@/box';
import { useActiveBrandConfig, useBrandUrls } from '@/brand';
import { CookieConsentButton } from '@/cookie-consent/CookieConsentButton.component';
import { CoreAppEvents } from '@/core/events';
import Copyright from '@/ennismore/components/Copyright.component';
import { useAppEvents } from '@/events';
import { useLanguage, useTranslation } from '@/i18n';
import { Divider, PageWrapper } from '@/ui/layout';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

import AnchorLink from './controls/AnchorLink.component';

const FooterLink = (props: {
  children: string;
  href: string;
  analyticsLabel: string;
}) => {
  const { colors } = useTheme();
  const events = useAppEvents<CoreAppEvents>();

  return (
    <BodySmall>
      <AnchorLink
        href={props.href}
        noUnderline
        openInNewTab
        color={colors.textPrimary}
        onClick={() =>
          events.emit('navigation', {
            location: 'Footer',
            originator: props.analyticsLabel,
          })
        }
      >
        {props.children}
      </AnchorLink>
    </BodySmall>
  );
};

export const Footer = () => {
  const brandConfig = useActiveBrandConfig();
  const urls = useBrandUrls();
  const cookieConsentConfig = brandConfig.analytics?.cookieConsent;
  const { t } = useTranslation('common');

  const { componentProperties, themeKey } = useTheme();

  const language = useLanguage();

  if (!urls) {
    throw new Error(
      `Legal urls not supplied for language [${language}]. This should never happen.`
    );
  }

  return (
    <>
      {themeKey === 'orientexpress' && (
        <Flex
          justifyContent={'center'}
          paddingBottom={'120px'}
          marginTop={['120px', '0']}
        >
          <Image
            src="/brands/orientexpress/images/footer-seal.svg"
            width={126.1}
            height={135}
            alt=""
          />
        </Flex>
      )}
      <footer
        css={css({
          backgroundColor: 'transparent',
          ...componentProperties.footer,
          span: {
            ...componentProperties.footer,
          },
          a: {
            ...componentProperties.footer,
          },
          ['@media all and (max-width: 720px)']: {
            marginTop: '40px',
          },
          ['div[role=separator]']: componentProperties.footer?.divider,
        })}
        role="contentinfo"
      >
        <Divider />
        <PageWrapper>
          <div
            css={css`
              display: flex;
              max-width: 1280px;
              margin: auto;
              flex-wrap: wrap;
              padding: 24px 0;

              @media all and (max-width: 975px) {
                display: grid;
                grid-template-columns: 1fr 1fr;

                .Footer__copyright {
                  width: 100%;
                  margin-top: 32px;
                }
              }

              @media all and (max-width: 500px) {
                grid-template-columns: 1fr;
              }

              .Footer__link {
                margin-right: 72px;

                @media all and (max-width: 700px) {
                  margin-right: 65px;
                  margin-bottom: ${componentProperties.footer?.mobile?.link
                    ?.marginBottom ?? '16px'};
                }
              }

              .Footer__copyright {
                margin-left: auto;
              }
            `}
          >
            <div className="Footer__link">
              <FooterLink
                href={urls.privacyPolicy}
                analyticsLabel="Privacy Policy"
              >
                {t('legal.privacyPolicy')}
              </FooterLink>
            </div>
            <div className="Footer__link">
              <FooterLink
                href={urls.cookiePolicy}
                analyticsLabel="Cookie Policy"
              >
                {t('legal.cookiePolicy')}
              </FooterLink>
            </div>
            {cookieConsentConfig && (
              <div className="Footer__link">
                <BodySmall>
                  {/* TODO: weirdly styling blends in perfectly without any CSS applied,
                    to revisit so that styles are definitely inherited from the FooterLinks surounding this. */}
                  <CookieConsentButton config={cookieConsentConfig}>
                    {t('legal.cookieSettings')}
                  </CookieConsentButton>
                </BodySmall>
              </div>
            )}
            <div className="Footer__link">
              <FooterLink href={urls.termsOfUse} analyticsLabel="Terms of Use">
                {t('legal.termsOfUse')}
              </FooterLink>
            </div>
            {urls.accessibility && (
              <div className="Footer__link">
                <FooterLink
                  href={urls.accessibility}
                  analyticsLabel="Accessibility"
                >
                  {t('legal.accessibility')}
                </FooterLink>
              </div>
            )}
            <div className="Footer__copyright">
              <Copyright />
            </div>
          </div>
        </PageWrapper>
      </footer>
    </>
  );
};

export default Footer;
