import { motion } from 'framer-motion';
import React from 'react';

import { Flex } from '@/box';

import { AccordionDropdownIndicator } from '../icons';
import { useTheme } from '../theme';
import RadioButton, { RadioButtonProps } from './RadioButton.component';

interface RadioButtonAccordionProps extends RadioButtonProps {
  collapsibleContent: React.ReactNode;
}

export const RadioButtonAccordion = ({
  children,
  collapsibleContent,
  ...props
}: RadioButtonAccordionProps) => {
  const {
    forms: { radioButton },
  } = useTheme();
  return (
    <RadioButton isExpandable {...props}>
      <Flex>
        <div css={{ flex: '1' }}>
          <div>{children}</div>
          <motion.div
            layout
            animate={props.isChecked ? { height: 'auto' } : { height: 0 }}
            style={{
              overflow: 'hidden',
            }}
            aria-hidden={!props.isChecked}
          >
            <div
              css={{
                padding: radioButton?.description?.padding ?? '16px 0 0 0',
              }}
            >
              {collapsibleContent}
            </div>
          </motion.div>
        </div>
        <div>
          <AccordionDropdownIndicator
            isFlipped={!props.isChecked}
            width={14}
            height={7}
          />
        </div>
      </Flex>
    </RadioButton>
  );
};

export default RadioButtonAccordion;
