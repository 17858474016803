import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { useActiveBrandConfig } from '@/brand';
import {
  useHotelCurrencyValue,
  usePreferredCurrency,
} from '@/currency-preview';
import { useAppEvents } from '@/events';
import { CurrencyCode } from '@/finance/enums/currency-code.enum';
import {
  getLocalisedCurrencyName,
  getLocalisedCurrencySymbol,
} from '@/finance/utils/formatting';
import { useAvailableLocales, useLocale } from '@/i18n';
import { InternationalizationEvents } from '@/i18n/events';
import { getLocalisedLanguageLabel } from '@/i18n/language-labels';
import { Text } from '@/ui/typography';

import { LanguageAndCurrencyDrawer } from '../drawer';
import { DropdownIndicator } from '../icons';
import { Divider } from '../layout';
import { useTheme } from '../theme';

const commonCurrencies = undefined;
// const commonCurrencies: CurrencyCode[] = ['GBP', 'EUR', 'USD'];
// const otherCurrencies = Object.values(currencyCodeSchema.Values).filter(
//   (curr) => !commonCurrencies.includes(curr)
// );

type AvailableTabs = 'language' | 'currency';

const StyledTrigger = ({
  children,
  onClick,
  includeDropdown,
}: {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  includeDropdown: boolean;
}) => {
  const { colors } = useTheme();
  const buttonStyle = css`
    display: flex;
    align-items: center;
    cursor: pointer;

    position: relative;
    touch-action: manipulation;

    &:focus:after {
      transform: scaleX(1);
      transform-origin: right;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: ${colors.textPrimary};
      height: 1px;
      width: 100%;
      bottom: -4px;
      will-change: transform;

      transform: scaleX(0);
      transform-origin: left;

      left: 0;
      transition: transform 0.3s ease-in-out;
    }

    .dropdown {
      color: ${colors.textPrimary};
      margin-left: 6px;
    }
  `;
  return (
    <LanguageAndCurrencyDrawer.Trigger onClick={onClick} css={buttonStyle}>
      {children}
      {includeDropdown ? (
        <div className="dropdown">
          <DropdownIndicator />
        </div>
      ) : null}
    </LanguageAndCurrencyDrawer.Trigger>
  );
};

const LanguageTrigger = ({
  language,
  setDefaultTab,
  includeDropdown,
}: {
  language: Intl.BCP47LanguageTag;
  setDefaultTab: (tab: AvailableTabs) => void;
  includeDropdown: boolean;
}) => (
  <StyledTrigger
    onClick={() => setDefaultTab('language')}
    includeDropdown={includeDropdown}
  >
    <Text
      style="bodySmall"
      aria-label={`Change language, selected: ${getLocalisedLanguageLabel(
        language
      )}.`}
    >
      {language.toUpperCase()}
    </Text>
  </StyledTrigger>
);

const CurrencyTrigger = ({
  currency,
  language,
  setDefaultTab,
  includeDropdown,
}: {
  currency: CurrencyCode;
  language: Intl.BCP47LanguageTag;
  setDefaultTab: (tab: AvailableTabs) => void;
  includeDropdown: boolean;
}) => (
  <StyledTrigger
    onClick={() => setDefaultTab('currency')}
    includeDropdown={includeDropdown}
  >
    <Text
      style="bodySmall"
      aria-label={`Change currency, selected: ${getLocalisedCurrencyName(
        language,
        currency
      )}.`}
    >
      {currency} {getLocalisedCurrencySymbol(language, currency)}
    </Text>
  </StyledTrigger>
);

export const LanguageAndCurrencySelector = () => {
  const router = useRouter();
  const locale = useLocale();
  const locales = useAvailableLocales();
  const [isLanguageAndCurrencyDrawerOpen, setIsLanguageAndCurrencyDrawerOpen] =
    useState(false);
  const [defaultTab, setDefaultTab] = useState<AvailableTabs>('language');
  const hotelCurrency = useHotelCurrencyValue();
  const [preferredCurrency, setPreferredCurrency] = usePreferredCurrency();
  const activeCurrency = preferredCurrency ?? hotelCurrency;
  const events = useAppEvents<InternationalizationEvents>();
  const { supportedCurrencies } = useActiveBrandConfig();

  // showcase language selector only if multiple languages are supported
  const displayLangugageSelector = locales.length > 1;

  // hide component altogether if it's not required
  if (!displayLangugageSelector && !activeCurrency) return null;

  const changeLanguage = (locale: string) => {
    events.emit('changeLanguage', { locale });
    router.push(router.asPath, undefined, { locale: locale.toLowerCase() });
  };

  return (
    <LanguageAndCurrencyDrawer
      isOpen={isLanguageAndCurrencyDrawerOpen}
      setOpen={setIsLanguageAndCurrencyDrawerOpen}
      availableLanguages={locales}
      availableCurrencies={{
        common: commonCurrencies,
        other: supportedCurrencies,
      }}
      selectedLanguage={locale.baseName}
      selectedCurrency={activeCurrency}
      baseCurrency={hotelCurrency}
      onLanguageSelect={changeLanguage}
      onCurrencySelect={setPreferredCurrency}
      defaultTab={defaultTab}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {displayLangugageSelector ? (
          <LanguageTrigger
            language={locale.baseName}
            setDefaultTab={setDefaultTab}
            includeDropdown={activeCurrency ? false : true}
          />
        ) : null}
        {displayLangugageSelector && activeCurrency ? (
          <Divider
            vertical
            marginLeft="6px"
            marginRight="6px"
            height="12px"
            inheritColor
          />
        ) : null}
        {activeCurrency ? (
          <CurrencyTrigger
            language={locale.baseName}
            currency={activeCurrency}
            setDefaultTab={setDefaultTab}
            includeDropdown
          />
        ) : null}
      </div>
    </LanguageAndCurrencyDrawer>
  );
};
