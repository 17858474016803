import { EnnismoreTheme } from '@/ui/theme/ennismore-theme.interface';

const fonts = {
  headline: 'bodoni-pro-black, serif',
  intro: 'trade-gothic-bold, sans-serif',
  subheader: 'trade-gothic-bold, sans-serif',
  body: 'trade-gothic-medium, sans-serif',
};

export const twentyFiveHoursTheme: EnnismoreTheme = {
  themeKey: '25hours',
  logoUrl: '/brands/25hours/images/logo.svg',
  faviconUrl: '/brands/25hours/icons/favicon.ico',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 'bold',
      // Original theme styles:
      // fontSize: '80px',
      // lineHeight: '88px',
      fontSize: '48px',
      lineHeight: '56px',
    },
    heading2: {
      fontFamily: fonts.subheader,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.subheader,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.subheader,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '18px',
      lineHeight: '28px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#000',
    textSecondary: '#757575',
    background: '#fffff6',
    primary100: '#f7f7e1', // glen only - ignore
    primary200: '#f7f7e1',
    primary300: '#2d2926',
    primary400: '#FFE7D9', // hox only
    primary500: '#2d2926',
    secondary100: '#f6f6f6',
    secondary200: '#d9d9d9',
    secondary300: '#2d2926',
    secondary400: '#2d2926',
    error: '#B00020',
  },
  buttons: {
    primary: {
      backgroundColor: '#df0510',
      fontFamily: fonts.subheader,
      textTransform: 'lowercase',
      fontSize: '18px',
      borderRadius: 0,
    },
    secondary: {
      backgroundColor: 'transparent',
      fontFamily: fonts.subheader,
      textTransform: 'lowercase',
      fontSize: '18px',
      borderRadius: 0,
      border: '1px solid #000',
      color: '#000',
    },
  },
  keylines: {
    border: '1px solid #ccc',
  },
  forms: {
    input: {},
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'bodoni-pro-black',
      fontWeight: 700,
      sources: {
        woff: '/brands/25hours/fonts/bodoni-pro-black.woff2',
      },
    },
    {
      fontFamily: 'trade-gothic-bold',
      fontWeight: 700,
      sources: {
        woff: '/brands/25hours/fonts/trade-gothic-bold.woff2',
      },
    },
    {
      fontFamily: 'trade-gothic-medium',
      fontWeight: 400,
      sources: {
        woff: '/brands/25hours/fonts/trade-gothic-medium.woff2',
      },
    },
  ],
  componentProperties: {
    search: {
      results: {
        roomSpecColumns: 2,
      },
    },
    sidebarCards: {
      secondary: {
        borderRadius: '0px',
        padding: 'xs',
        backgroundColor: 'background',
        borderColor: 'primary300',
        color: 'textPrimary',
      },
      primary: {
        borderRadius: '0px',
        padding: 'l',
        backgroundColor: 'primary200',
        borderColor: 'white',
        color: 'textPrimary',
      },
    },
    banners: {
      backgroundColor: 'primary200',
      color: 'textPrimary',
    },
    cards: {
      primary: {
        border: '1px solid #ccc',
        mobile: {
          border: 'none',
        },
      },
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
