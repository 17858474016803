import { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'PortraitText, Times',
  intro: 'PortraitText, Times',
  subheader: 'Work Sans, sans-serif',
  body: 'Work Sans, sans-serif',
};

export const gleneaglesTheme: EnnismoreTheme = {
  themeKey: 'gleneagles',
  logoUrl: '/brands/gleneagles/images/logo.svg',
  faviconUrl: '/brands/gleneagles/favicons/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 200,
      // Original theme styles:
      // fontSize: '80px',
      // lineHeight: '88px',
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.headline,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.headline,
      fontSize: '16px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '24px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '10px',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#192620',
    textSecondary: '#6b736f',
    background: '#F7F8F7',
    primary100: '#F7F8F7',
    primary200: '#E6E9E6',
    primary300: '#D3D9D3',
    primary400: '#AABBAE',
    primary500: '#104520',
    secondary100: '#F6F6F6',
    secondary200: '#D9D9D9',
    secondary300: '#626865',
    secondary400: '#3B423F',
    error: '#B00020',
  },
  buttons: {
    primary: {
      fontFamily: 'Work Sans, sans-serif',
      backgroundColor: '#3B423F',
      fontWeight: 400,
      fontSize: 16,
    },
    secondary: {
      fontFamily: 'Work Sans, sans-serif',
      border: '1px solid #3D3937',
      color: '#192620',
      backgroundColor: 'transparent',
      fontWeight: 400,
      fontSize: 16,
    },
  },
  keylines: {
    border: '1px solid #D3D9D3',
  },
  forms: {
    input: {},
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'PortraitText',
      fontWeight: 300,
      fontStyle: 'normal',
      sources: {
        woff: '/brands/gleneagles/fonts/PortraitText-Regular-Web.woff',
        woff2: '/brands/gleneagles/fonts/PortraitText-Regular-Web.woff2',
      },
    },
    {
      fontFamily: 'PortraitText',
      fontWeight: 300,
      fontStyle: 'italic',
      sources: {
        woff: '/brands/gleneagles/fonts/PortraitText-RegularItalic-Web.woff',
        woff2: '/brands/gleneagles/fonts/PortraitText-RegularItalic-Web.woff2',
      },
    },
    {
      fontFamily: 'Work Sans',
      fontWeight: 400,
      sources: {
        woff: 'https://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8JoI3ZKyHaQQ.woff',
      },
    },
  ],
  componentProperties: {
    search: {
      results: {
        roomSpecColumns: 1,
      },
    },
    sidebarCards: {
      secondary: {
        borderRadius: '4px',
        padding: 'l',
        backgroundColor: 'primary200',
        borderColor: 'primary300',
        color: 'textPrimary',
        hideBorder: true,
      },
      primary: {
        borderRadius: '4px',
        padding: 'l',
        backgroundColor: 'primary200',
        color: 'textPrimary',
      },
    },
    banners: {
      backgroundColor: 'primary200',
      color: 'textPrimary',
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: false,
  },
};
